@import '../../../../scss/theme-bootstrap';

.cart-item {
  &.kits {
    .cart-item__kit-items {
      clear: both;
      .cart-item {
        padding-bottom: 0;
        border: none;
      }
    }
  }
}
